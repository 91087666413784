<template>
  <label :class="['toggle', {checked: value}]">
    <span class="toggle__text"><slot/></span>
    <input type="checkbox" class="toggle__input" :checked="value" @change="$emit('input', $event.target.checked)"/>
    <i class="toggle__facade"/>
  </label>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    value: Boolean
  }
}
</script>

<style lang="less" scoped>
.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__text {
    font-weight: 600;
    margin-right: 20px;
  }

  &__facade {
    display: block;
    background-color: #e5e5e5;
    width: 46px;
    min-width: 46px;
    height: 22px;
    border-radius: 20px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 4px;
      left: 4px;
    }

    :checked + & {
      background-color: #16b547;

      &::after {
        left: 28px;
      }
    }
  }
}
</style>
