import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueMask from 'v-mask'
import * as Sentry from "@sentry/vue";
// import VueJivosite from '@bchteam/vue-jivosite'
import VueGtag from 'vue-gtag'

// Vue.use(VueJivosite, { widgetId: 'Us5E46xQlm' })

Vue.use(VueMask);

Vue.use(VueYandexMetrika, {
  id: 81901300,
  env: process.env.NODE_ENV,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true

  }
})

Vue.use(VueGtag, {
  config: {
    id: 'UA-215911798-1'
  }
})

Sentry.init({
  Vue,
  dsn: "https://f9e9105661a04d228ce85fa082dac863@sentry.yadda.io/2",
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
