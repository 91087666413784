import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/landings/Landing3.vue';
import Settings from '../views/Settings';
import UserAgreement from '../views/UserAgreement';
import Scanner from '../views/Scanner';
import Typeform from '../views/Typeform';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Scanner',
        component: Scanner
    },
    {
        path: '/pricing',
        name: 'Landing',
        component: Landing,
        meta: {jivosite: true, globalFooter: false}
    },
    {
        path: '/settings',
        name: 'ScannerSettings',
        component: Settings
    },
    {
        path: '/user-agreement',
        name: 'UserAgreement',
        component: UserAgreement
    },
    {
        path: '/contact-us',
        name: 'Typeform',
        component: Typeform
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    if (!['Landing', 'UserAgreement', 'Typeform'].includes(to.name) && !window.sessionStorage.getItem('dismissedLanding')) {
        next({name: 'Landing'})
    } else next()
})

export default router
