<template>
  <header class="header">
    <div class="header__title">
      <a href="https://yadda.io" class="header__logo" target="_blank">
        <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.40758 6.349C0 9.95219 0 14.9681 0 25C0 35.0319 0 40.0478 2.40758 43.651C3.44984 45.2109 4.78914 46.5502 6.349 47.5924C9.95219 50 14.9681 50 25 50C35.0319 50 40.0478 50 43.651 47.5924C45.2109 46.5502 46.5502 45.2109 47.5924 43.651C50 40.0478 50 35.0319 50 25C50 14.9681 50 9.95219 47.5924 6.349C46.5502 4.78914 45.2109 3.44984 43.651 2.40758C40.0478 0 35.0319 0 25 0C14.9681 0 9.95219 0 6.349 2.40758C4.78914 3.44984 3.44984 4.78914 2.40758 6.349ZM11.5 15.2833L22.3964 12.5L24.9212 21.6452L27.3131 16.6087L41 13.0302L28.6419 36.6222L14.955 40.3333L23.1937 24.4286L14.4234 27.2119L11.5 15.2833Z"/>
        </svg>
      </a>
      <router-link to="/">
        ЧекQR
      </router-link>
    </div>

    <router-link to="/settings" class="header__settings" v-if="showSettings">
      <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4153 22.4789C14.684 23.7183 16.2123 24.338 18 24.338C19.7877 24.338 21.316 23.7183 22.5847 22.4789C23.8534 21.2394 24.4878 19.7465 24.4878 18C24.4878 16.2535 23.8534 14.7606 22.5847 13.5211C21.316 12.2817 19.7877 11.662 18 11.662C16.2123 11.662 14.684 12.2817 13.4153 13.5211C12.1466 14.7606 11.5122 16.2535 11.5122 18C11.5122 19.7465 12.1466 21.2394 13.4153 22.4789ZM31.7541 19.7746L35.6468 22.7324C36.0505 23.0141 36.1081 23.4085 35.8198 23.9155L32.1001 30.169C31.8694 30.5634 31.4946 30.6761 30.9756 30.507L26.3909 28.7324C25.1798 29.5775 24.1418 30.169 23.2767 30.507L22.5847 35.2394C22.4694 35.7465 22.181 36 21.7197 36H14.2803C13.819 36 13.5306 35.7465 13.4153 35.2394L12.7233 30.507C11.6276 30.0563 10.5895 29.4648 9.60913 28.7324L5.02443 30.507C4.50541 30.6761 4.13056 30.5634 3.89988 30.169L0.180216 23.9155C-0.10813 23.4085 -0.0504606 23.0141 0.353224 22.7324L4.24589 19.7746C4.18823 19.3803 4.15939 18.7887 4.15939 18C4.15939 17.2113 4.18823 16.6197 4.24589 16.2254L0.353224 13.2676C-0.0504606 12.9859 -0.10813 12.5915 0.180216 12.0845L3.89988 5.83099C4.13056 5.43662 4.50541 5.32394 5.02443 5.49296L9.60913 7.26761C10.8202 6.42254 11.8582 5.83099 12.7233 5.49296L13.4153 0.760563C13.5306 0.253521 13.819 0 14.2803 0H21.7197C22.181 0 22.4694 0.253521 22.5847 0.760563L23.2767 5.49296C24.3724 5.94366 25.4105 6.53521 26.3909 7.26761L30.9756 5.49296C31.4946 5.32394 31.8694 5.43662 32.1001 5.83099L35.8198 12.0845C36.1081 12.5915 36.0505 12.9859 35.6468 13.2676L31.7541 16.2254C31.8118 16.6197 31.8406 17.2113 31.8406 18C31.8406 18.7887 31.8118 19.3803 31.7541 19.7746Z"/>
      </svg>
    </router-link>

<!--    <button class="header__cta" @click="dismissLanding" v-if="showCTA">-->
<!--      Попробуйте бесплатно в любом <span>браузере -></span>-->
<!--    </button>-->

    <div class="header__contacts" v-if="showContacts">
      <a href="https://wa.me/message/YSSTZDLXRM66H1" target="_blank"><img src="@/assets/img/whatsapp.svg"></a>
      <a href="https://t.me/checkqr_ru_bot" target="_blank"><img src="@/assets/img/telegram.svg"></a>
      <a href="tel:+74993017499" class="mobile"><span class="material-icons">phone</span></a>
      <a href="tel:+74993017499" class="desktop">+7 499 301 74 99</a>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    dismissLanding() {
      window.sessionStorage.setItem('dismissedLanding', 'true')
      this.$router.push('/')
    }
  },
  computed: {
    showSettings() {
      return this.$route.name === 'Scanner'
    },
    showContacts() {
      return this.$route.name === 'Landing'
    }
  }
}
</script>

<style lang="less" scoped>
//@import 'https://header-info.yadda.moskovskiy.org/fonts/Mont/style.css';

.header {
  padding: 20px;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  gap: 2rem;
  flex-wrap: wrap;

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #228756;

    a {
      display: block;
      font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
      font-size: 20px;
    }

    svg {
      display: block;
      width: 40px;
      height: 40px;
      fill: currentColor;
    }
  }

  &__logo {
    margin-right: 10px;
  }

  &__settings {
    display: block;

    svg {
      width: 20px;
      height: 20px;
      fill: currentColor;
    }
  }

  //&__cta {
  //  background-color: black;
  //  color: white;
  //  padding: 15px 20px;
  //  border-radius: 10px;
  //  font-weight: 700;
  //  font-family: inherit;
  //  font-size: 1rem;
  //  border: none;
  //  cursor: pointer;
  //
  //  span {
  //    white-space: nowrap;
  //  }
  //}
  //
  //@media (max-width: 650px) {
  //  &__cta {
  //    width: 100%;
  //  }
  //}

  &__contacts {
    display: flex;
    gap: 25px;
    align-items: center;
    font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
    font-size: 1.3rem;

    img {
      display: block;
      height: 30px;
      width: 30px;
    }

    span {
      display: block;
    }

    .mobile {
      display: none;
    }

    @media (max-width: 840px) {
      .desktop {
        display: none;
      }

      .mobile {
        display: block;
      }
    }
  }
}
</style>
