<template>
  <main class="settings">
    <Back></Back>

    <h2>Настройки</h2>
    <Toggle :value="settings.sound" @input="changeSetting('sound', $event)">Воспроизводить звук при сканировании</Toggle>
  </main>
</template>

<script>
import Back from '../components/Back';
import Toggle from '../components/Toggle';
export default {
  name: 'Settings',
  components: {Toggle, Back},
  data() {
    return {
      sound: false
    }
  },
  methods: {
    changeSetting(key, value) {
      this.$store.dispatch('changeSetting', {key, value})
    }
  },
  computed: {
    settings() {
      return this.$store.state.scannerSettings
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/page.less";
</style>
