import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authToken: null,
        authDate: null,
        dismissedLanding: null,
        scannerSettings: {
            sound: true
        }
    },
    mutations: {
        SET_AUTH_TOKEN(state, token) {
            state.authToken = token
            state.authDate = new Date().getTime()
        },
        SET_DISMISSED_LANDING(state, time) {
            state.dismissedLanding = time
        },
        SET_SETTING(state, {key, value}) {
            state.scannerSettings[key] = value
        }
    },
    actions: {
        setAuthToken({commit}, token) {
            commit('SET_AUTH_TOKEN', token)
        },
        dismissLanding({commit}, time) {
            commit('SET_DISMISSED_LANDING', time)
        },
        changeSetting({commit}, {key, value}) {
            commit('SET_SETTING', {key, value})
        }
    },
    modules: {},
    plugins: [createPersistedState()]
})
