<template>
  <main :class="['wrapper', stateType]">
    <router-link to="/pricing" class="promo">
      Хотите готовый сканер, брендированную версию на своем домене или API?
    </router-link>
    <div class="title">
      <!--        <img src="@/assets/img/qr-logo.svg" alt="Проверка сертификата" class="title__logo" v-if="state==='scanning'"/>-->
      <span v-if="state === 'scanning'">Отсканируйте код</span>
      <span class="title__text-success" v-if="state==='scan-success' && scanResult.expirationDate">
        Сертификат действителен до {{ formatDate(scanResult.expirationDate) }}
      </span>
      <span class="title__text-success" v-else-if="state==='scan-success'">Сертификат действительный!</span>
      <span class="title__text-fail" v-else-if="state==='scan-fail'">Такого сертификата нет в системе</span>
      <span class="title__text-wrong-domain"
            v-else-if="state==='scan-wrong-domain'">QR-код содержит неверный домен: {{ domain }}</span>
      <span class="title__text-error" v-else-if="state==='scan-error'">Произошла ошибка :(</span>
    </div>

    <main>
      <Camera @init="onCameraInit" @detect="onDetect" @error="onError"/>

      <div class="scanning" v-if="state==='scanning'">
        <p><b>Наведите камеру на QR-код</b></p>
      </div>

      <Result v-if="state==='scan-success'" :result="scanResult"/>

      <div class="error" v-if="error||offline">
        <div class="error__modal" v-if="error">{{ error }}</div>
        <div class="error__modal error__modal_offline" v-else-if="offline">Нет подключения к интернету. Пожалуйста,
          убедитесь, что вы подключены к сети.
        </div>
      </div>

      <p v-if="state==='scan-fail'"><b>К сожалению, данного посетителя нельзя допустить внутрь</b></p>
      <p v-else-if="state==='scan-wrong-domain' && fraud"><b>Вас пытаются обмануть! Это ненастоящий сертификат,
        посетителя нельзя допустить внутрь.</b></p>

      <div class="links">
        <a href="https://t.me/checkqr" target="_blank">
          <svg viewBox="0 0 534 534">
            <path
                d="M266.666 533.332C413.942 533.332 533.332 413.942 533.332 266.666C533.332 119.39 413.942 0 266.666 0C119.39 0 0 119.39 0 266.666C0 413.942 119.39 533.332 266.666 533.332Z"
                fill="url(#paint0_linear)"/>
            <path
                d="M110.982 264.356L290.558 190.367C308.284 182.66 368.4 157.998 368.4 157.998C368.4 157.998 396.145 147.208 393.833 173.412C393.063 184.202 386.897 221.967 380.731 262.814L361.464 383.816C361.464 383.816 359.922 401.542 346.82 404.625C333.718 407.708 312.138 393.835 308.284 390.752C305.202 388.44 250.481 353.758 230.443 336.803C225.048 332.178 218.882 322.93 231.213 312.14C258.959 286.706 292.1 255.107 312.138 235.069C321.387 225.82 330.635 204.24 292.1 230.444L183.429 303.662C183.429 303.662 171.098 311.369 147.977 304.433C124.855 297.496 97.8803 288.248 97.8803 288.248C97.8803 288.248 79.3832 276.687 110.982 264.356V264.356Z"
                fill="white"/>
            <defs>
              <linearGradient id="paint0_linear" x1="355.575" y1="88.9092" x2="222.242" y2="399.999"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#37AEE2"/>
                <stop offset="1" stop-color="#1E96C8"/>
              </linearGradient>
            </defs>
          </svg>

          <span>Чат поддержки ЧекQR</span>
        </a>

<!--        <i></i>-->

<!--        <a href="https://www.mos.ru/upload/documents/docs/35-YM-kln32.pdf" target="_blank">-->
<!--          <svg viewBox="0 0 13 16">-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                  d="M0.889214 15.9364C0.571374 15.8381 0.296914 15.5927 0.145016 15.2708C0.0010428 14.9658 0 14.9136 0 7.9856C0 1.02045 0.000278088 1.00697 0.147449 0.695176C0.236294 0.506944 0.407032 0.306663 0.576866 0.19137L0.858834 7.62281e-07H6.5H12.1412L12.4231 0.19137C12.593 0.306663 12.7637 0.506944 12.8526 0.695176C12.9989 1.00526 13 1.04861 13 6.5062C13 10.2137 12.9759 12.0702 12.9262 12.2055C12.8168 12.5025 9.68889 15.7085 9.35027 15.8706C9.0886 15.9959 8.8368 16.0034 5.07487 15.9992C2.87634 15.9967 0.992797 15.9684 0.889214 15.9364ZM7.79805 13.137C7.82364 11.5156 7.84901 11.3875 8.21496 11.0371C8.56589 10.7011 8.79829 10.6594 10.3213 10.6594H11.7487V5.9892V1.31905H6.5H1.25134V7.9856V14.6522H4.51274H7.77414L7.79805 13.137ZM10.2888 13.1905L11.4342 12.0141H10.2706H9.10695V13.1905C9.10695 13.8376 9.11516 14.367 9.1251 14.367C9.13511 14.367 9.65879 13.8376 10.2888 13.1905ZM2.90241 9.22765C2.83546 9.18765 2.73181 9.05795 2.67209 8.93938C2.53284 8.66324 2.59604 8.35365 2.83025 8.16471C3.00307 8.02532 3.1063 8.02125 6.5 8.02125C9.8937 8.02125 9.99693 8.02532 10.1698 8.16471C10.2676 8.24364 10.3677 8.40171 10.3921 8.516C10.4438 8.75728 10.2878 9.11942 10.084 9.23121C9.90162 9.33139 3.07029 9.32797 2.90241 9.22765ZM3.05882 6.617C2.70921 6.48174 2.53201 6.11612 2.6454 5.76382C2.79236 5.30715 2.75238 5.31185 6.5 5.31185C10.2476 5.31185 10.2076 5.30715 10.3546 5.76382C10.4448 6.04425 10.3794 6.27675 10.1505 6.4883C10.001 6.62641 9.8889 6.63133 6.57967 6.64409C4.70058 6.65137 3.11618 6.63917 3.05882 6.617ZM2.90241 3.88015C2.83546 3.84015 2.73181 3.71045 2.67209 3.59188C2.53284 3.31574 2.59604 3.00615 2.83025 2.81721C3.00307 2.67781 3.1063 2.67375 6.5 2.67375C9.8937 2.67375 9.99693 2.67781 10.1698 2.81721C10.2676 2.89614 10.3677 3.05421 10.3921 3.1685C10.4438 3.40978 10.2878 3.77191 10.084 3.88371C9.90162 3.98389 3.07029 3.98047 2.90241 3.88015Z"-->
<!--                  fill="rgb(60, 60, 60, 0.82)"/>-->
<!--          </svg>-->
<!--          <span>Указ мэра Москвы о порядке проверки QR кодов</span>-->
<!--        </a>-->
      </div>
    </main>

    <footer class="footer">
      <a href="https://cards.yadda.io/?referer=checkqr" target="_blank" class="footer__link simployal">
        <img src="@/assets/img/simployal.svg" alt="Simployal"/>
        <span><strong>Simployal</strong> — удобные онлайн меню, карта лояльности, чаевые и предзаказ</span>
      </a>
      <a href="https://yadda.io?referer=checkqr" target="_blank" class="footer__link yadda">
        <img src="@/assets/img/yadda.svg" alt="Yadda"/>
        <span><strong>Yadda</strong> — сайты, приложения, сервисы для вашего бизнеса</span>
      </a>
    </footer>
  </main>
</template>

<script>
import Camera from '../components/CameraNew';
import Result from '../components/Result';

const sounds = {
  success: require('@/assets/sound/scan-success.mp3'),
  fail: require('@/assets/sound/scan-fail.mp3')
}

export default {
  name: 'Scanner',
  components: {Result, Camera},
  data() {
    return {
      state: 'scanning',
      scanResult: null,
      error: null,
      offline: false,
      domain: null,
      fraud: false,
      // serviceStatus: null
    }
  },
  methods: {
    onCameraInit(result) {
      if (result?.error)
        if (result.error.name === 'NotAllowedError') {
          this.error = 'Без доступа к камере приложение не может работать. Пожалуйста, разрешите доступ к камере в настройках сайта и обновите страницу.'
        } else {
          this.error = 'Не удалось подключиться к камере. Попробуйте открыть сайт в другом браузере.'
        }
    },
    onDetect(result) {
      this.domain = result.certDomain
      this.fraud = result.isFraud
      if (result.isValid) {
        this.scanResult = result.cert
        this.state = 'scan-success'
        this.playSound('success')
      } else {
        this.playSound('fail')
        this.scanResult = null
        if (result.message === 'wrong url') {
          this.state = 'scan-wrong-domain'
        } else {
          this.state = 'scan-fail'
        }
      }
    },
    onError() {
      this.scanResult = null
      this.state = 'scan-error'
      this.playSound('fail')
    },
    formatDate(date) {
      const d = new Date(date);
      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
      return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()} года`
    },
    playSound(type) {
      if (this.$store.state.scannerSettings.sound) {
        if (type === 'success') {
          const sound = new Audio(sounds.success)
          sound.play()
        } else if (type === 'fail') {
          const sound = new Audio(sounds.fail)
          sound.play()
        }
      }
    }
  },
  computed: {
    stateType() {
      if (this.state === 'scan-success') {
        return 'scan-success'
      } else if (['scan-fail', 'scan-error', 'scan-wrong-domain'].includes(this.state)) {
        return 'scan-fail'
      } else {
        return 'scanning'
      }
    }
  },
  watch: {
    stateType(val) {
      this.$root.$el.dataset.scannerState = val
    }
  },
  mounted() {
    if (!window.navigator.onLine) this.offline = true;

    addEventListener('offline', () => {
      this.offline = true
    })
    addEventListener('online', () => {
      this.offline = false
    })
  },
  beforeDestroy() {
    this.$root.$el.dataset.scannerState = null
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem;
  max-width: 800px;
  margin: auto;
  min-height: 100%;
  flex: 1;
  text-align: center;
}

.promo {
  background-color: rgba(211, 216, 225, .5);
  color: #53627C;
  padding: 20px;
  border-radius: 7px;
  font-weight: 600;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  margin: 2rem auto;

  &__logo {
    display: block;
    width: 200px;
  }
}

.scanning {
  p {
    color: var(--text);

    &.secondary {
      color: #999;
      font-size: .96rem;
    }
  }
}

.error {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    background: white;
    padding: 20px;
    line-height: 1.5;
    border-radius: 8px;
    width: calc(100% - 40px);
    max-width: 800px;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3691 22.4688C17.892 22.4688 22.3691 17.9916 22.3691 12.4688C22.3691 6.9459 17.892 2.46875 12.3691 2.46875C6.84629 2.46875 2.36914 6.9459 2.36914 12.4688C2.36914 17.9916 6.84629 22.4688 12.3691 22.4688Z' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.3691 8.46875V12.4688' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.3691 16.4688H12.3791' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      display: block;
    }

    &_offline::before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M1.24219 1.30859L23.2422 23.3086' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.9629 11.3672C17.782 11.7669 18.5479 12.2675 19.2429 12.8572' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.24219 12.8588C6.72427 11.6196 8.50806 10.795 10.4122 10.4688' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.9531 5.36029C13.0955 5.18765 15.2508 5.44845 17.2902 6.12711C19.3296 6.80576 21.2113 7.88836 22.8231 9.31029' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.66211 9.30969C3.04927 8.08354 4.63981 7.10891 6.36211 6.42969' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.77148 16.4173C9.78669 15.6961 11.0012 15.3086 12.2465 15.3086C13.4918 15.3086 14.7063 15.6961 15.7215 16.4173' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.2422 20.3086H12.2522' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white' transform='translate(0.242188 0.308594)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
}

.links {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  a {
    text-align: center;
    flex: 1;
    color: #1f1f1f;
    padding: 15px 0;

    svg {
      height: 1.6rem;
      width: auto;
      display: block;
      margin: auto auto 15px;
    }

    span {
      font-size: 0.78rem;
      font-weight: 600;
      display: block;
      line-height: 1.5;
    }
  }

  > i {
    display: block;
    width: 1px;
    background-color: #35353533;
    margin: 0 15px;
  }
}

.footer {
  margin-top: auto;
  z-index: 99;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: white;
    text-decoration: none;
    text-align: start;
    padding: 14px 18px;
    border-radius: 18px;
    margin-top: 10px;
    border: 1px solid var(--border);

    &:hover {
      background-color: #f6f6f6;
    }

    img {
      display: block;
      height: 20px;
    }

    &.simployal {
      --brand-color: #001DD6;
    }

    &.yadda {
      --brand-color: #363494;
    }

    span {
      color: #1f1f1f;
      font-size: .85rem;

      strong {
        color: var(--brand-color);
      }
    }
  }
}
</style>
