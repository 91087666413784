<template>
  <main class="contact-us">
    <div id="wrapper" data-tf-widget="u3L6Bowf" data-tf-inline-on-mobile></div>
  </main>
</template>

<script>
export default {
  name: 'Typeform',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://embed.typeform.com/next/embed.js'
    document.head.appendChild(script)
  }
}
</script>

<style lang="less" scoped>
main, /deep/ div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/deep/ iframe {
  flex: 1;
}
</style>
