<template>
  <footer>
    <router-link to="/user-agreement">Пользовательское соглашение</router-link>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto auto 0;
  padding: 20px;

  a {
    text-decoration: none;
    color: #a5a5a5;
    font-size: 14px;
  }
}
</style>
