<template>
  <div class="video">
    <div class="video__backdrop" @click="$emit('close')"></div>
    <div class="video__embed">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoOverlay',
  mounted() {
    document.body.style.overflowY = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflowY = 'auto'
  }
}
</script>

<style lang="less" scoped>
.video {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.23);
    position: absolute;
    cursor: pointer;
  }

  &__embed {
    z-index: 1;

    iframe {
      width: ~'max(50vw, 750px)';
      height: ~'max(calc(50vw * .5625), calc(750px * .5625))';

      @media (max-width: 750px) {
        width: 100vw;
        height: calc(100vw * .5625);
      }
    }
  }
}
</style>
