<template>
  <main class="landing">
    <section class="hero">
      <div>
        <h1>Быстрая проверка сертификатов вакцинации</h1>
        <h3>
          <a class="underline" @click="$refs.plus.scrollIntoView()">На любом смартфоне</a> или с помощью
          <a class="underline" @click="$refs.kiosk.scrollIntoView()">специального устройства</a><br>
          Находим подделки, спасаем от штрафов.
        </h3>

        <div class="hero__clients">
          <img src="@/assets/img/landing/azimut.png">
<!--          <img src="@/assets/img/landing/expocentre.png">-->

          <span class="hero__clients-more">И ещё десятки компаний<br>и <strong>миллионы посетителей</strong></span>
        </div>

        <div class="buttons">
          <button @click="dismissLanding">Попробовать в браузере</button>
<!--          <button class="primary" @click="$refs.kiosk.scrollIntoView()">Купить сканер-киоск</button>-->
        </div>
      </div>

      <img src="@/assets/img/landing/kiosk_hero1.png" class="hero__image">
    </section>

    <section class="features">
      <p>
        Сканируем все виды сертификатов:<br>
        Используем Госуслуги и mos.ru, оперативно добавляем новые форматы сертификатов<br>
        До 5 раз быстрее, чем обычным приложением «Камера» — не нужно переключать камеру и браузер
      </p>

      <div class="features__links">
        <a @click="$refs.kiosk.scrollIntoView()">Киоск для сканирования на входе</a>
        <a @click="$refs.plus.scrollIntoView()">Наш софт на вашем устройстве</a>
      </div>
    </section>

    <section id="kiosk" class="kiosk" ref="kiosk">
      <div class="kicker">Быстро. Непрерывно</div>
      <h1>Готовый киоск для проверки</h1>
      <p>Работает по Wi-Fi от розетки<br>
        3 секунды на человека<br>
        Поддержка сертификатов всех форматов<br>
        Аналитика в личном кабинете
      </p>
      <div class="buttons">
<!--        <router-link to="/contact-us" class="button primary">Купить за 30 000₽</router-link>-->
        <button class="button secondary" @click="showKioskVideo = true">Смотреть видео</button>
      </div>
<!--      <div class="label"><span class="material-icons">local_shipping</span>Поставка по всей России всего от 4 дней</div>-->
<!--      <small>Лицензия на ПО (2 200₽ в месяц) не входит в стоимость киоска<br>-->
<!--        Внешний вид устройства может отличаться</small>-->

      <div class="kiosk__features">
        <div class="kiosk__features-column">
          <div class="kiosk__feature">
            <span class="kiosk__feature-title">Яркий экран</span>
            <span class="kiosk__feature-description">Демонстрация изображения с камеры для удобства сканирования и
            обратная связь</span>
          </div>

          <div class="kiosk__feature">
            <span class="kiosk__feature-title">Надежный корпус</span>
            <span class="kiosk__feature-description">
              Экран облачён в твёрдый пластик и установлен на металлический постамент
            </span>
          </div>
        </div>

        <div class="kiosk__image">
          <img src="@/assets/img/landing/kiosk.png"/>
        </div>

        <div class="kiosk__features-column">
          <div class="kiosk__feature">
            <span class="kiosk__feature-title">Быстрая камера</span>
            <span class="kiosk__feature-description">Средняя скорость считывания QR-кода до 3 секунд</span>
          </div>
          <div class="kiosk__feature">
            <span class="kiosk__feature-title">Готовое решение</span>
            <span class="kiosk__feature-description">
              Вам нужно только включить устройство в розетку и подключить Wi-Fi
            </span>
          </div>
        </div>
      </div>
    </section>

    <section id="reviews" class="reviews" ref="reviews">
      <h1 class="center">Нам доверяют, потому что успешно проверяют</h1>

      <div class="reviews__columns">
        <div class="reviews__column">
          <div class="reviews__card review shadow">
            <p><b>Кофейня в Иркутске</b> использует <a class="underline" @click="$refs.kiosk.scrollIntoView()">Сканер-киоск</a> на входе, чтобы
              посетители не толпились и не нервничали на входе.</p>
            <div class="review__bubble">
              <p>
                Мы очень волновались, что новые ограничения похоронят наш бизнес, но решение от ЧекQR нас спасло. Сканер
                быстро приехал и так же быстро работает: очереди на вход исчезли, посетители стали проходить гораздо быстрее.
                А главное – люди перестали жаловаться на проверку кодов, теперь все проходят быстро и легко. Пару раз пытались зайти с подделкой, но система их
                распознала и не пропустила.
              </p>
            </div>
            <div class="review__author">
              <div class="review__author-name">
                <b>Игорь</b>
                <span>Владелец кофейни</span>
              </div>
              <div class="review__author-photo">
                <img src="@/assets/img/landing/review-photo.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="reviews__column">
          <div class="reviews__card with-buttons">
            <div class="reviews__card-content">
              <p><b>Экспоцентр в Москве</b> использует API от ЧекQR, чтобы проверять посетителей в собственной
                системе</p>
            </div>
            <div class="reviews__card-buttons">
              <router-link to="/contact-us">Заказать индивидуальное решение -></router-link>
            </div>
          </div>
          <div class="reviews__card bright with-buttons">
            <div class="reviews__card-content">
              <p>Используйте готовые решения ЧекQR: <a class="underline" @click="$refs.plus.scrollIntoView()">Плюс</a>,
                <a class="underline" @click="$refs.kiosk.scrollIntoView()">Сканер-киоск</a>, или создайте
                индивидуальное вместе с нами</p>
            </div>
            <div class="reviews__card-buttons">
              <button @click="dismissLanding">Сканер в браузере</button>
              <button class="secondary">Готовое устройство</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="plus" class="with-image" ref="plus">
      <div class="section-text">
        <div class="kicker">Ещё удобнее и приятнее</div>
        <h1>ЧекQR Плюс</h1>
        <p>Проверка на вашем устройстве нашим софтом<br>
          Персональное брендирование<br>
          Поддержка USB-сканеров<br>
          Защита от подделок<br>
          Мобильное приложение*
        </p>
        <div class="buttons">
<!--          <button class="button primary" @click="formProductId = 1">Всего за 999₽ в месяц</button>-->
          <a href="https://demo.checkqr.ru" target="_blank" class="button secondary">Демо-версия</a>
        </div>
      </div>
      <div class="section-image">
        <img src="@/assets/img/landing/plus.png"/>
      </div>
    </section>

    <section id="handheld" class="with-image">
      <div class="section-text">
        <div class="kicker">Всё. Сразу</div>
        <h1>Ручной сканер</h1>
        <p>Встроенный термометр<br>
          Работает по Wi-Fi от аккумулятора<br>
          1 человек каждые 3 секунды<br>
          Поддержка сертификатов всех форматов
        </p>
<!--        <div class="buttons">-->
<!--          <router-link to="/contact-us" class="button">Предзаказать за 25 000₽</router-link>-->
<!--        </div>-->
<!--        <small>Лицензия на ПО (2 200₽ в месяц) не входит в стоимость сканера<br>-->
<!--          Внешний вид устройства может отличаться</small>-->
      </div>
      <div class="section-image">
        <img src="@/assets/img/landing/scanner1.png"/>
      </div>
    </section>

    <section id="contact" class="contact">
      <p>Остались вопросы?</p>
      <p>Пишите <a href="mailto:mail@checkqr.ru">mail@checkqr.ru</a><br>
        <a href="tel:+74993017499">+7 499 301-74-99</a><br>
        <a href="https://t.me/checkqr_ru_bot" target="_blank">Telegram</a>
      </p>
      <p>
        <router-link to="/contact-us" class="accent">Заполните форму</router-link>
        , и мы с вами свяжемся
      </p>
      <p><a href="https://t.me/checkqr_ru_bot" target="_blank" class="accent">Хотите API?</a></p>
    </section>

    <footer>
      <p>Система отображает данные, полученные с государственных сервисов, без изменений и не несёт ответственности за
        их достоверность</p>
      <p>* Мобильное приложение будет доступно позднее</p>
      <p>
        <router-link to="/user-agreement">Пользовательское соглашение</router-link>
      </p>
    </footer>

    <FormModal v-if="formProductId" :product-id="formProductId" @close="formProductId = null"/>
    <VideoOverlay v-if="showKioskVideo" @close="showKioskVideo = false">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/0WbkYqYDOeE?autoplay=1"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
    </VideoOverlay>
  </main>
</template>

<script>
import VideoOverlay from '../../components/VideoOverlay.vue';
import FormModal from '../../components/FormModal.vue';

export default {
  name: 'Landing3',
  components: {VideoOverlay, FormModal},
  data() {
    return {
      formProductId: null,
      showKioskVideo: false
    }
  },
  methods: {
    dismissLanding() {
      window.sessionStorage.setItem('dismissedLanding', 'true')
      this.$router.push('/')
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/page';

.landing {
  max-width: initial;
  padding: 20px 0;
  --padding-x: ~'max(calc((100vw - 1000px - 80px) / 2), 30px)';
  gap: 0;

  h1, h2, h3, h4 {
    font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
  }

  h1 {
    font-size: 2rem;
    margin-top: 0;
    font-weight: 500;

    &.center {
      text-align: center;
      font-size: 1.6rem;
      font-weight: 800;
      margin-bottom: 2rem;
    }
  }

  h3 {
    font-weight: 700;
  }

  p {
    font-size: 1.1rem;
  }

  a {
    cursor: pointer;
  }

  a.underline {
    color: currentColor;
    border-bottom: 1px solid currentColor;
  }

  section {
    width: 100%;
    padding: 4rem var(--padding-x);
    align-items: center;

    &.with-image {
      display: flex;
      gap: 100px;

      .section-text {
        flex: 1;

        p {
          a {
            padding-bottom: 3px;
            border-bottom: 1px solid currentColor;
          }
        }
      }

      .section-image {
        flex-basis: 22%;
        align-self: flex-end;
        margin: 0 5%;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    .kicker {
      font-weight: 600;
      color: #989898;
      margin-bottom: 10px;
      font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
    }

    small {
      display: block;
      color: #BBBBBB;
      font-size: .8rem;
      font-weight: 600;
      margin-top: 10px;
    }
  }

  button, .button {
    font-family: 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
    font-weight: 700;
    font-size: .95rem;
    color: white;
    border: none;
    padding: 15px 25px;
    border-radius: 10px;
    background-color: black;
    cursor: pointer;

    &.primary {
      background-color: #5338F6;
    }

    &.secondary {
      background-color: #dfdfdf;
      color: @text;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .label {
    margin: 20px 0;
    padding: 15px 25px;
    border-radius: 10px;
    background-color: @red[bg];
    color: @red[text];
    width: fit-content;
    font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
    display: flex;
    align-items: center;
    gap: 15px;

    .material-icons {
      font-size: 1.5rem;
      line-height: 0;
    }
  }

  .hero {
    width: calc(100% - 40px);
    max-width: 1200px;
    margin: auto;
    padding: 60px 50px 0;
    border-radius: 15px;
    background-color: #DCFFDF;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;

    h1 {
      font-weight: 800;
      font-size: 2.2rem;
    }

    h3 {
      font-family: 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
      font-size: 1.2rem;
      line-height: 1.5;
    }

    &__clients {
      display: flex;
      gap: 40px;
      margin: 4rem 0;

      img {
        display: block;
        height: 2.5rem;
      }

      &-more {
        font-size: .95rem;
        font-weight: 600;
        color: rgba(25, 23, 47, 0.5);

        strong {
          color: @text;
        }
      }
    }

    .buttons {
      margin-bottom: 4rem;
    }

    &__image {
      display: block;
      width: 240px;
      height: auto;
      max-width: 180px;
    }
  }
}

.features {
  padding-top: 2rem;

  &__links {
    display: flex;
    //justify-content: center;
    gap: 1rem 4rem;
    font-size: 1.1rem;
    flex-wrap: wrap;
    margin-top: 2rem;

    a {
      color: #5338F6;
      font-weight: 600;
    }
  }
}

.kiosk {
  background-color: #F4F5FF;

  &__features {
    width: 100%;
    display: flex;
    margin-top: 3rem;

    &-column {
      width: 35%;
      position: relative;
    }
  }

  &__feature {
    position: absolute;

    &-title {
      display: block;
      font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
      font-size: 1.3rem;
      margin-bottom: 5px;
    }

    &-description {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  &__features-column:first-of-type &__feature {
    &:first-child {
      top: 5%;
    }

    &:last-child {
      top: 55%;
    }
  }

  &__features-column:last-of-type &__feature {
    &:first-child {
      top: 30%;
    }

    &:last-child {
      top: 80%;
    }
  }

  &__image {
    width: 20%;
    margin: 0 50px;

    img {
      width: 100%;
      display: block;
    }
  }
}

.reviews {
  &__columns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;


    @media (max-width: 960px) {
      flex-direction: column;
    }
  }

  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: inherit;
  }

  &__card {
    background-color: #F4F4F4;
    border-radius: 15px;
    padding: 30px;
    flex: 1;

    &.shadow {
      box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.08);
    }

    &.with-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.bright {
      background-color: #5338F6;
      color: white;
    }

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }

    &-buttons {
      margin-top: 20px;
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      a, button {
        display: block;
        width: fit-content;
        font-family: 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;
        color: @text;
        font-size: .97rem;
        font-weight: 600;
        background-color: rgba(83, 56, 247, 0.06);
        padding: 14px 25px;
        border-radius: 18px;
        cursor: pointer;
        text-align: center;

        .bright & {
          background-color: white;
          color: #5338F6;

          &.secondary {
            background-color: rgba(255, 255, 255, 0.2);
            color: white;
          }
        }

        @media (max-width: 840px) {
          width: 100%;
        }
      }
    }
  }

  .review {
    > p {
      padding: 0 20px;
    }

    &__bubble {
      color: #322293;
      padding: 20px;
      background-color: white;
      border: 1px solid #ededed;
      border-radius: 12px;
      position: relative;
      margin-bottom: 50px;

      &::after {
        content: '';
        position: absolute;
        right: 40px;
        top: calc(100% - 0px);
        height: 60px;
        width: 60px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='90' height='60' viewBox='0 0 90 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.59217 0.75H89.25V58.5691L38.004 23.1146L37.9911 23.1056L37.9778 23.0972L2.59217 0.75Z' fill='white' stroke='%23EDEDED' stroke-width='1.5'/%3E%3Cpath d='M88.5 0H2.82422L5.97211 2H88.5V0Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

    &__author {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 0 20px;

      &-name {
        display: flex;
        flex-direction: column;
      }

      &-photo {
        background-color: #efefef;
        border-radius: 50%;
        overflow: hidden;
        width: 50px;
        height: 50px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          &[src=''] {
            opacity: 0;
          }
        }
      }
    }
  }
}

#plus {
  background-color: #101011;
  color: white;
}

#contact {
  p {
    font-size: 2rem;
    font-family: 'Mont', 'Manrope', 'Helvetica Neue', -apple-system, sans-serif;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #5338F6;
    display: inline-block;

    &.accent {
      z-index: 1;
      display: inline;
      background-image: linear-gradient(#fff 30%, #FFFB97 30%);
    }
  }
}

.landing footer {
  padding: 2rem var(--padding-x);
  color: #BBBBBB;

  p {
    font-size: .9rem;
  }
}

@media (max-width: 840px) {
  .landing {
    section.with-image {
      flex-direction: column;
      gap: 2rem;

      .section-image {
        max-width: 200px;
        align-self: initial;
        order: 2;
      }
    }

    .hero {
      display: block;
      padding: 40px 30px 0;

      &__clients {
        display: flex;
        gap: 30px 40px;
        margin: 4rem 0;
        flex-wrap: wrap;

        img {
          max-width: calc(50% - 20px);
          height: fit-content;
          object-fit: contain;
          object-position: center 0;
        }
      }

      .buttons {
        button {
          width: 100%;
          font-size: 1rem;
          padding: 20px;
          border-radius: 17px;
        }
      }

      &__image {
        margin: auto;
      }
    }
  }


  .kiosk {
    &__features {
      flex-direction: column;
      gap: 2rem;

      &-column {
        width: auto;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
      }
    }

    &__feature {
      position: static;
    }

    &__image {
      width: auto;
      order: -1;

      img {
        max-width: 200px;
        margin: auto;
      }
    }
  }
}
</style>
