<template>
  <div id="app" class="not-ready" v-if="state !== 'ready'">
    <div class="app__loader" v-if="state === 'loading'"></div>
    <div class="app__error" v-if="state === 'error'">
      <svg viewBox="0 0 26 23">
        <path d="M13 8.07946V12.7196M13 17.3598H13.0115M11.036 2.11683L1.30783 18.5199C1.10726 18.8707 1.00113 19.2684 1.00001 19.6736C0.998886 20.0787 1.10281 20.477 1.30143 20.829C1.50005 21.1809 1.78646 21.4742 2.13215 21.6797C2.47784 21.8851 2.87077 21.9955 3.27184 22H22.7282C23.1292 21.9955 23.5222 21.8851 23.8679 21.6797C24.2135 21.4742 24.4999 21.1809 24.6986 20.829C24.8972 20.477 25.0011 20.0787 25 19.6736C24.9989 19.2684 24.8927 18.8707 24.6922 18.5199L14.964 2.11683C14.7593 1.7759 14.471 1.49402 14.1269 1.2984C13.7829 1.10277 13.3948 1 13 1C12.6052 1 12.2171 1.10277 11.8731 1.2984C11.529 1.49402 11.2407 1.7759 11.036 2.11683Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span>Что-то пошло не так. Пожалуйста, перезагрузите страницу.</span>
    </div>
  </div>
  <div id="app" :class="{'show-jivosite': $route.meta.jivosite}" v-else>
    <Header v-if="!hideHeader"/>
    <router-view/>
    <Footer v-if="$route.meta.globalFooter !== false"/>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  components: {Footer, Header},
  data() {
    return {
      state: 'ready'
    }
  },
  methods: {
    reload() {
      window.location.reload()
    }
  },
  computed: {
    hideHeader() {
      return ['ScannerSettings'].includes(this.$route.name)
    },
  }
}
</script>

<style lang="less">
@import (css) url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&family=Source+Code+Pro:wght@400;600&display=swap');

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  color: #363636;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: currentColor;
}

#app {
  font-family: Manrope, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  width: 100%;
  min-height: 100%;

  --bg: #FDFDFD;
  --text: #1F1F1F;
  --border: #e2e2e2;

  &[data-scanner-state='scan-success'] {
    --bg: #BDFFC8;
    --text: #005226;
    --border: #90E29D;
  }

  &[data-scanner-state='scan-fail'], &[data-scanner-state='scan-error'] {
    --bg: #FFDDDB;
    --text: #FF363D;
    --border: #E4C0BE;
  }

  &.not-ready {
    align-items: center;
    justify-content: center;
  }
}

.app {
  &__loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(202, 202, 202, 0.2);
    border-right: 5px solid rgba(202, 202, 202, 0.2);
    border-bottom: 5px solid rgba(202, 202, 202, 0.2);
    border-left: 5px solid #c4c4c4;
    transform: translateZ(0);
    animation: loader 1.1s infinite linear;

    &, &::after {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }

    @keyframes loader {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  &__error {
    padding: 20px;
    text-align: center;
    color: #ff5b5b;
    line-height: 1.5;

    svg {
      stroke: #ff5b5b;
      fill: none;
      height: 2rem;
      display: block;
      margin: auto auto 1rem;
    }
  }
}
</style>

<style>
#app:not(.show-jivosite) ~ jdiv, #app:not(.show-jivosite) ~ #jivo-iframe-container {
  display: none !important;
}
</style>
