<template>
  <label class="input">
    <input :type="type" :name="name" :placeholder="placeholder" @input="emit" :required="required" :disabled="disabled"
           @blur="emit" v-mask="type==='tel'?'+7##########':null" v-model="value" @focus="focus" invalid="invalid"/>
    <span class="input__error" v-if="error">{{ error }}</span>
  </label>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {},
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    required: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      // value: '',
      error: null,
      e: null,
      invalid: false
    }
  },
  methods: {
    emit(e) {
      const validate = this.validate(e.target.value)
      if (validate.valid) {
        this.$emit('input', e.target.value)
        this.invalid = false
        this.error = null
      } else {
        this.$emit('input', e.target.value)
        this.invalid = true
        this.error = validate.error
      }
    },
    validate(value) {
      if (this.required && !value.length) return {valid: false, error: 'Это обязательное поле'}

      if (this.type === 'email') {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(value).toLowerCase())) {
          return {valid: false, error: 'Неверный email'}
        }
        return {valid: true}
      } else if (this.type === 'tel') {
        if (value.length < 12) {
          return {valid: false, error: 'Неверный номер телефона'}
        } else {
          return {valid: true}
        }
      } else if (this.type === 'password') {
        let errors = []
        if (value.length < 8) errors.push('быть не короче 8 символов')
        if (/^\d+$/.test(value)) errors.push('содержать буквы')
        if (errors.length === 1) return {valid: false, error: 'Пароль должен ' + errors[0]}
        if (errors.length > 1) return {
          valid: false,
          error: 'Пароль должен ' + errors.slice(0, -1).join(', ') + ' и ' + errors.slice(-1)
        }

        return {valid: true}
      }

      return {valid: true}
    },
    focus() {
      if (this.type === 'tel' && (['+', '+7'].includes(this.value) || !this.value.length)) {
        this.$emit('input', '+7')
        // this.value = '+7'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.input {
  display: block;

  input {
    width: 100%;
    font-family: inherit;
    font-size: 1em;
    appearance: none;
    background: none;
    border: 1px solid #e3e3e3;
    color: currentColor;
    padding: 12px 15px;
    border-radius: 10px;
    outline: none;

    &:focus {
      border-color: #7386ff;
      box-shadow: 0 3px 14px 0 rgba(210, 216, 255, .34);
    }

    &::placeholder {
      font-size: .95em;
    }
  }

  &__error {
    font-size: .85rem;
    font-weight: 600;
    color: #ff7260;
  }
}
</style>
