<template>
  <main class="policies">
    <h1>Пользовательское соглашение</h1>
    <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ИП Анисимов Д. В.
      (далее CheckQR или Администрация) с одной стороны и пользователем сайта с другой.<br>
      Сайт CheckQR не является средством массовой информации.</p>

    <p>Используя сайт, Вы соглашаетесь с условиями данного соглашения.<br>
      Если Вы не согласны с условиями данного соглашения, не используйте сайт CheckQR!</p>

    <h2>Права и обязанности сторон</h2>
    <p>Пользователь имеет право:</p>
    <ul>
      <li>получать информацию на сайте</li>
      <li>использовать информацию сайта в личных некоммерческих целях</li>
      <li>использовать информацию сайта в коммерческих целях без специального разрешения</li>
    </ul>

    <p>Администрация имеет право:</p>
    <ul>
      <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
      <li>ограничивать доступ к любой информации на сайте</li>
      <li>отказывать в регистрации без объяснения причин</li>
    </ul>

    <p>Пользователь обязуется:</p>
    <ul>
      <li>не нарушать работоспособность сайта</li>
      <li>не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам</li>
    </ul>

    <p>Администрация обязуется:</p>
    <ul>
      <li>поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от
        Администрации
        причинам.
      </li>
    </ul>

    <p>Ответственность сторон</p>
    <ul>
      <li>администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг</li>
      <li>администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
      <li>в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и
        т.
        д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу
        информационного ресурса
      </li>
    </ul>

    <h2>Условия действия Соглашения</h2>
    <p>Данное Соглашение вступает в силу при любом использовании данного сайта.<br>
      Соглашение перестает действовать при появлении его новой версии.<br>
      Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему
      усмотрению.<br>
      Администрация не оповещает пользователей об изменении в Соглашении.</p>

    <h2>Политика возвратов</h2>
    <h3>Вы не смогли активировать продукт, и наша техническая поддержка не ответила вам в течение 24 часов после
      обращения.</h3>
    <p>Если вы столкнулись с проблемой активации программы и сотрудники нашей службы поддержки не вышли с вами на связь
      в
      течение 24 часов после вашего обращения, вы вправе требовать возврата денежных средств.</p>

    <h3>В программе есть проблема технического характера, которую не смогли решить специалисты нашей службы
      поддержки.</h3>
    <p>Если вы столкнулись с технической проблемой во время использования программы, свяжитесь с нашей службой
      поддержки.
      Пожалуйста, предоставьте подробное описание проблемы: описание, скриншоты, а также любые дополнительно
      запрашиваемые
      сведения. Без этой информации мы не сможем вернуть вам деньги. Если указанная вами проблема не решена сотрудниками
      службы поддержки в течение 30 дней с момента вашего обращения, вы вправе потребовать возврат денежных средств.</p>
  </main>
</template>

<script>
export default {
  name: 'UserAgreement'
}
</script>

<style lang="less" scoped>
@import "~@/styles/page.less";

h1, h2, h3, p, ul {
  margin: 0;
}
</style>
