<template>
  <header class="back">
    <button class="back__button" @click="goBack">
      <svg viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" fill="none">
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    </button>
  </header>
</template>

<script>
export default {
  name: 'Back',
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__button {
    background: none;
    border: none;
    padding: 10px 10px 10px 0;
    cursor: pointer;

    svg {
      display: block;
      width: 24px;
      height: 24px;
      stroke: currentColor;
      stroke-width: 2px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
