<template>
  <div class="form">
    <div class="form__backdrop" @click="$emit('close')"></div>
    <div class="form__body">
      <h3>
        ЧекQR Плюс

        <button class="form__close" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          </svg>
        </button>
      </h3>
      <form @submit.prevent="sendForm">
        <Input type="email" placeholder="Email*" v-model="formData.username" required/>
        <Input type="password" placeholder="Пароль*" v-model="formData.password" required/>
        <Input type="text" placeholder="Название организации*" v-model="formData.organization_name" required/>
        <Input type="tel" placeholder="Телефон*" v-model="formData.phone_number" required/>
        <button :class="['button', 'small', {error: state === 'error'}]">
          <span v-if="state === 'idle'">Подписаться (999₽/мес.)</span>
          <span v-else-if="state === 'loading'">Загружаем...</span>
          <span v-else-if="state === 'error'">Ошибка, попробуйте снова</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Input from './Input';

export default {
  name: 'FormModal',
  components: {Input},
  props: {
    productId: Number
  },
  data() {
    return {
      formData: {
        username: null,
        password: null,
        organization_name: null,
        phone_number: null
      },
      state: 'idle'
    }
  },
  methods: {
    sendForm() {
      if (this.state === 'loading') return
      this.state = 'loading'
      return fetch('https://data.checkqr.ru/api/v2/org/registration_payment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({...this.formData, id: this.productId})
      }).then(r => r.json()).then(r => {
        if (r.success && 'redirect_url' in r) {
          window.location.href = r.redirect_url
        }
      }).catch(() => {
        this.state = 'error'
      })
    }
  },
  mounted() {
    document.body.style.overflowY = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflowY = 'auto'
  }
}
</script>

<style lang="less" scoped>
.form {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.23);
    position: absolute;
  }

  &__body {
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    height: fit-content;
    z-index: 1;
    width: 100%;
    max-width: ~'min(500px, 100vw - 40px)';
    max-height: calc(100vh - 40px);
    box-shadow: 0 4px 4px 0 rgba(134, 134, 134, 0.23);

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 15px;
      margin: 0 0 1rem;

      button {
        display: block;
        padding: 0;
        background: none;
        border: none;
        width: fit-content;

        svg {
          display: block;
          width: 22px;
          height: 22px;
          fill: #a5a5a5;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    button {
      width: 100%;
    }
  }
}
</style>
